<template>
    <v-card outlined>
        <v-tabs v-model="currentTab"  >
            <v-tab>Fiscalizações - Data Limite</v-tab>
            <v-tab>Fiscalizações - Data Fiscalização</v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab" class="px-4 pt-6 pb-2">
            <v-tab-item>
                <fiscalizacoes-por-periodo-chart filtro="data_limite"/>
            </v-tab-item>
            <v-tab-item>
                <fiscalizacoes-por-periodo-chart filtro="data_fiscalizacao" />
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
    import FiscalizacoesPorPeriodoChart from "./FiscalizacoesPorPeriodoChart";
    export default {
        name: "RelatoriosGraficos",
        components: {FiscalizacoesPorPeriodoChart},
        data(){
            return {
                currentTab: 0,
            }
        },
    }
</script>

<style scoped>

</style>