<template>
    <div style="position: relative; height: 300px; margin: auto">
        <bar-chart :height="300" :chart-data="fiscalizacoesPorPeriodo" :options="chartOptions" />
    </div>
</template>

<script>
    import gql from "graphql-tag";
    import BarChart from "../../../components/charts/BarChart";

    export default {
        name: "FiscalizacoesPorPeriodoChart",
        components: {BarChart},
        props: {
            filtro: {
                type: String,
                reqiured: true,
            },
        },
        data(){
            return {
                fiscalizacoesPorPeriodo: {},
                chartOptions: {
                    maintainAspectRatio:false,
                    responsive: true,
                    legend:{
                        display: false,
                    },
                    scales:{
                        xAxes: [{
                            gridLines: {
                                display:false
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                display:true,
                                color: 'rgb(246,246,246)'
                            }
                        }],

                    },

                },
            }
        },
        mounted() {
            this.$apollo.query({
                query: gql`query ($filtro: String!, $tipo: String!) {
                    fiscalizacoesPorPeriodo(filtro: $filtro, tipo: $tipo) {
                        periodo
                        total
                    }

                }`,
                variables: {
                    filtro: this.filtro,
                    tipo: "mensal"
                }
            }).then(result => {
                let fiscalizacoesPorPeriodo = result.data.fiscalizacoesPorPeriodo;
                let data = {
                    labels: fiscalizacoesPorPeriodo.map(f => this.$moment(f.periodo, 'YYYY-MM').format("MMM/YY")),
                    datasets: [{
                        label: "Fiscalizacoes",
                        data: fiscalizacoesPorPeriodo.map(f => f.total),
                        backgroundColor: "rgb(234,243,235)",
                        borderColor: "rgb(129,199,132)",
                        borderWidth: 1,
                        minBarLength: 4
                    }],

                }
                this.fiscalizacoesPorPeriodo = data;
            }).catch(() => {
            });
        }
    }
</script>

<style scoped>

</style>