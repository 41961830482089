<template>
    <v-timeline
            align-top
            dense class="overflow-y-auto overflow-x-hidden" style="max-height: 400px"
    >
        <v-timeline-item
                color="pink"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>5pm</strong>
                </v-col>
                <v-col>
                    <strong>New Icon</strong>
                    <div class="caption">Mobile App</div>
                </v-col>
            </v-row>
        </v-timeline-item>

        <v-timeline-item
                color="teal lighten-3"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>3-4pm</strong>
                </v-col>
                <v-col>
                    <strong>Design Stand Up</strong>
                    <div class="caption mb-2">Hangouts</div>
                    <v-avatar>
                        <v-img
                                src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                        ></v-img>
                    </v-avatar>
                    <v-avatar>

                        <v-img
                                src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                        ></v-img>
                    </v-avatar>
                    <v-avatar>
                        <v-img
                                src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
                        ></v-img>
                    </v-avatar>
                </v-col>
            </v-row>
        </v-timeline-item>

        <v-timeline-item
                color="pink"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>12pm</strong>
                </v-col>
                <v-col>
                    <strong>Lunch break</strong>
                </v-col>
            </v-row>
        </v-timeline-item>

        <v-timeline-item
                color="teal lighten-3"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>9-11am</strong>
                </v-col>
                <v-col>
                    <strong>Finish Home Screen</strong>
                    <div class="caption">Web App</div>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item
                color="pink"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>5pm</strong>
                </v-col>
                <v-col>
                    <strong>New Icon</strong>
                    <div class="caption">Mobile App</div>
                </v-col>
            </v-row>
        </v-timeline-item>

        <v-timeline-item
                color="teal lighten-3"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>3-4pm</strong>
                </v-col>
                <v-col>
                    <strong>Design Stand Up</strong>
                    <div class="caption mb-2">Hangouts</div>
                    <v-avatar>
                        <v-img
                                src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                        ></v-img>
                    </v-avatar>
                    <v-avatar>

                        <v-img
                                src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                        ></v-img>
                    </v-avatar>
                    <v-avatar>
                        <v-img
                                src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
                        ></v-img>
                    </v-avatar>
                </v-col>
            </v-row>
        </v-timeline-item>

        <v-timeline-item
                color="pink"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>12pm</strong>
                </v-col>
                <v-col>
                    <strong>Lunch break</strong>
                </v-col>
            </v-row>
        </v-timeline-item>

        <v-timeline-item
                color="teal lighten-3"
                small
        >
            <v-row class="pt-1">
                <v-col cols="3">
                    <strong>9-11am</strong>
                </v-col>
                <v-col>
                    <strong>Finish Home Screen</strong>
                    <div class="caption">Web App</div>
                </v-col>
            </v-row>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
    export default {
        name: "Timeline"
    }
</script>

<style scoped>

</style>