import gql from "graphql-tag";
<template>
    <page>
        <v-row no-gutters v-if="totalLembretes > 0">
            <v-col>
                <v-alert color="error" dark border="left"
                         dense class="my-0 py-0">
                    <v-row align="center">
                        <v-col class="shrink">
                            <v-icon>
                                mdi-alert
                            </v-icon>
                        </v-col>
                        <v-col class="grow">
                            <span class="">Você possui </span>
                            <span class="font-weight-bold">{{totalLembretes}}</span>
                            <span class=""> lembretes</span>
                        </v-col>
                        <v-col class="shrink">
                            <v-btn light small :to="{name:'lembretes'}">Mais detalhes</v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
<!--        <v-row>
            <v-col sm="6" md="7" cols="12">
                <v-row dense>
                    <v-col>
                        <v-alert class="custom-alert fill-height text-right mb-0" text color="light-blue darken-3" border="left">
                            <span class="subtitle-1 font-weight-medium text-uppercase">Total de Fiscalizações</span>
                            <span class="display-3 font-weight-black d-block">
                                {{fiscalizacoesStatus.totalFiscalizacoes}}
                            </span>
                        </v-alert>
                    </v-col>
                    <v-col>
                        <v-alert class="custom-alert fill-height text-right mb-0" text color="teal" border="left">
                            <span class="subtitle-1 font-weight-medium text-uppercase">Fiscalizações Finalizadas</span>
                            <span class="display-3 font-weight-black d-block">
                                {{fiscalizacoesStatus.fiscalizacoesFinalizadas}}
                            </span>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col>
                        <v-alert class="custom-alert fill-height text-right mb-0" text dark color="orange lighten-2" border="left">
                            <span class="subtitle-1 font-weight-medium text-uppercase">Fiscalizações Futuras</span>
                            <span class="display-3 font-weight-black d-block">
                                {{fiscalizacoesStatus.fiscalizacoesPendentes}}
                            </span>
                        </v-alert>
                    </v-col>
                    <v-col>
                        <v-alert class="custom-alert fill-height text-right mb-0" text dark color="red lighten-2" border="left">
                            <span class="subtitle-1 font-weight-medium text-uppercase">Fiscalizações Pendentes</span>
                            <span class="display-3 font-weight-black d-block">
                                {{fiscalizacoesStatus.fiscalizacoesAtrasadas}}
                            </span>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="6" md="5" cols="12">
                <v-row dense class="fill-height">
                    <v-col>
                        <notas-fiscais-lancamentos-futuros />
                    </v-col>
                </v-row>

            </v-col>
        </v-row>-->
        <v-row>
            <v-col sm="5" cols="12">
                <fiscalizacoes-por-tipo-chart />
            </v-col>
            <v-col sm="7" cols="12" >
                <relatorios-graficos />
            </v-col>
        </v-row>
        <v-row >
            <v-col class="">
                <fiscalizacoes-map />
            </v-col>
        </v-row>
        <!--<v-row dense>
            <v-col cols="3" v-if="false">
                <timeline />
            </v-col>
        </v-row>-->
    </page>
</template>

<script>
    // @ts-ignore
    import Page from '../../components/Page'
    // @ts-ignore
    import FiscalizacoesMap from "./fragments/FiscalizacoesMap";
    // @ts-ignore
    import Timeline from "./fragments/Timeline";
    import gql from 'graphql-tag';
    // @ts-ignore
    import FiscalizacoesPorPeriodoChart from "./fragments/FiscalizacoesPorPeriodoChart";
    // @ts-ignore
    import RelatoriosGraficos from "./fragments/RelatoriosGraficos";
    import FiscalizacoesPorTipoChart from "./fragments/FiscalizacoesPorTipoChart";
    import NotasFiscaisLancamentosFuturos from "./fragments/NotasFiscaisLancamentosFuturos";
    export default {
        name: "Index",
        components: {
            NotasFiscaisLancamentosFuturos,
            FiscalizacoesPorTipoChart,
            RelatoriosGraficos, FiscalizacoesPorPeriodoChart, Page, Timeline, FiscalizacoesMap},
        data(){
           return {
               fiscalizacoesStatus: {
                   totalFiscalizacoes: '-',
                   fiscalizacoesFinalizadas: '-',
                   fiscalizacoesPendentes: '-',
                   fiscalizacoesAtrasadas: '-',
               },
           }
        },
        computed:{
            totalLembretes(){
                return this.$store.state.lembrete.total;
            }
        },
        methods: {
            /*getFiscalizacoesStatus(){
                this.$apollo.query({
                    query: gql`query {
                            fiscalizacoesStatus{
                                totalFiscalizacoes
                                fiscalizacoesFinalizadas
                                fiscalizacoesPendentes
                                fiscalizacoesAtrasadas
                            }
                        }`,
                }).then(result => {
                    this.fiscalizacoesStatus = result.data.fiscalizacoesStatus;
                })
            },*/
            getTotalLembretes(){
                /*this.$apollo.query({
                    query: gql`query {
                            totalLembretes
                        }`,
                }).then(result => {
                    this.totalLembretes = result.data.totalLembretes;
                    this.$store.commit("lembrete/setTotalLembretes", this.totalLembretes)
                })*/
            },

        },
        mounted() {
            document.title = "JF Assessoria Rural"
            //this.getFiscalizacoesStatus();
            this.getTotalLembretes();
        }
    }
</script>

<style >
    .custom-alert .v-alert__content,
    .custom-alert .v-alert__wrapper{
        height: 100%;
    }
    .custom-alert .v-alert__content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }



    /*@media(max-width: 1263px){
        .custom-alert .custom-headline{
            font-size: 1rem;
        }

        .custom-alert .custom-display-4{
            font-size: 5rem;
            line-height: 5rem;
        }
    }
    @media(max-width: 959px){
        .custom-alert .custom-headline{
            font-size: 0.8rem;
        }

        .custom-alert .custom-display-4{
            font-size: 3.5rem;
            line-height: 3.5rem;
        }
    }
    @media(max-width: 599px){
        .alerts_container .col{
            padding: 4px;
        }
        .custom-alert .custom-headline{
            font-size: 0.6rem;
        }

        .custom-alert .custom-display-4{
            font-size: 2rem;
            line-height: 2rem;
        }
    }*/
</style>