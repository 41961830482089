<template>
    <v-card outlined class="fill-height">
        <v-card-title class="subtitle-2 text-uppercase">
            <span>Fiscalizações por Tipo</span>
        </v-card-title>
        <v-card-text class="px-1">
            <pie-chart :height="300" :chart-data="fiscalizacoesPorTipo" :options="chartOptions" />
        </v-card-text>
    </v-card>
</template>

<script>
import PieChart from "../../../components/charts/PieChart";
import gql from "graphql-tag";
export default {
    name: "FiscalizacoesPorTipoChart",
    components: {PieChart},
    data(){
        return {
            fiscalizacoesPorTipo: {},
            chartOptions: {
                maintainAspectRatio:false,
                responsive: true,
                legend: {
                    position: 'top',
                    align: 'start',
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let fpt = data.payload[tooltipItem.index];
                            return fpt.tipoNome + ": " + fpt.porcentagem+"%"
                        }
                    }
                }
            },
        }
    },
    mounted() {
        this.$apollo.query({
            query: gql`query {
                fiscalizacoesPorTipo{
                    total
                    porcentagem
                    tipoId
                    tipoNome
                }
            }`,
        }).then(result => {
            let fiscalizacoesPorTipo = result.data.fiscalizacoesPorTipo;
            let data = {
                payload: fiscalizacoesPorTipo, //Passdo por mim para pegar no label
                labels: fiscalizacoesPorTipo.map(f => {
                    if(!f.tipoNome){
                        f.tipoNome = 'Não Definido'
                    }
                    return f.tipoNome + ': ' + f.total + ''
                }),
                datasets: [{
                    label: "Fiscalizacoes",
                    data: fiscalizacoesPorTipo.map(f => f.total),
                    backgroundColor: [
                        'rgba(38,166,154,0.5)',
                        'rgba(212,225,87,0.5)',
                        'rgba(141,110,99,0.5)',
                        'rgba(171,71,188,0.5)',
                    ],
                    borderColor: [
                        '#26A69A',
                        '#D4E157',
                        '#8D6E63',
                        '#AB47BC',
                        '#afafaf',
                    ],
                    //borderColor: "rgb(129,199,132)",
                    borderWidth: 1,
                    //minBarLength: 4
                }],
            }
            this.fiscalizacoesPorTipo = data;
        })
    }
}
</script>

<style scoped>

</style>