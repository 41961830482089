<template>
    <v-card flat class="fill-height" color="teal" dark>
        <v-card-title class="subtitle-2 text-uppercase py-2">
            <span class="mr-2">Lançamentos Futuros (Notas Fiscais)</span>
            <v-btn @click="" :to="{name:'nota_fiscal_lancamentos_futuros'}" x-small color="white" light rounded>Mais Detalhes</v-btn>
        </v-card-title>
        <v-simple-table dense height="210px" fixed-header class="table-lancamentos-futuros" >
            <template v-slot:default>
                <thead>
                <tr>
                    <th class="text-left">
                        Data
                    </th>
                    <th class="text-left">
                        Quantidade
                    </th>
                    <th class="text-right">
                        Valor
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr style="white-space: nowrap" v-for="(lancamento, index) in notasFiscaisLancamentosFuturos"
                    :key="index">
                    <td>{{ lancamento.dataPagamento | moment("DD/MM/YYYY") }}</td>
                    <td>{{ lancamento.quantidade }}</td>
                    <td class="font-weight-medium text-right">{{ lancamento.valorTotal | formatNumber}}</td>
                </tr>
                </tbody>
                <tfoot style="white-space: nowrap">
                <tr class="foot-total">
                    <td class="font-weight-medium">TOTAL</td>
                    <td class="font-weight-medium">{{quantidadeTotalNotasFuturas}}</td>
                    <td class="font-weight-medium text-right">{{valorTotalNotasFuturas | formatNumber}}</td>
                </tr>
                <tr class="foot-nao-recebidos">
                    <td >Não Recebidas</td>
                    <td class="">{{somaNotasFiscaisNaoRecebidas.total}}</td>
                    <td class="font-weight-medium text-right" >{{somaNotasFiscaisNaoRecebidas.valor | formatNumber}}</td>
                </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "NotasFiscaisLancamentosFuturos",
    data(){
        return {
            notasFiscaisLancamentosFuturos: [],
            somaNotasFiscaisNaoRecebidas: null,
        }
    },
    computed:{
        quantidadeTotalNotasFuturas(){
            let total = 0;
            this.notasFiscaisLancamentosFuturos.forEach(nf => {
                total += nf.quantidade;
            })
            return total;
        },
        valorTotalNotasFuturas(){
            let total = 0;
            this.notasFiscaisLancamentosFuturos.forEach(nf => {
                total += nf.valorTotal;
            })
            return total;
        }
    },
    mounted() {
        this.$apollo.query({
            query: gql`query {
                            notasFiscaisLancamentosFuturosTotais{
                                quantidade
                                valorTotal
                                dataPagamento
                            }
                            somaNotasFiscaisNaoRecebidas{
                                total
                                valor
                            }
                        }`,
        }).then(result => {
            this.notasFiscaisLancamentosFuturos = result.data.notasFiscaisLancamentosFuturosTotais;
            this.somaNotasFiscaisNaoRecebidas = result.data.somaNotasFiscaisNaoRecebidas;
        })
    }
}
</script>

<style scoped>
    .table-lancamentos-futuros{
        background-color: transparent !important;
    }
    .table-lancamentos-futuros thead th,
    .table-lancamentos-futuros tfoot td{
        background-color: #00897B !important;
    }
    .table-lancamentos-futuros tfoot td{
        position: sticky;
        z-index: 2;
    }
    .table-lancamentos-futuros tfoot .foot-total td{
        bottom: 32px;
    }
    .table-lancamentos-futuros tfoot .foot-nao-recebidos td{
        bottom: 0;
    }
    .table-lancamentos-futuros tbody tr:hover{
        background-color: #26A69A !important;
    }
</style>