<template>
    <v-card outlined class="pa-4 pt-1 pb-3">
        <span class="caption">
            Fiscalizações por município
        </span>
        <v-row dense class="mt-1 d-flex flex-row">
            <v-col md cols="col-sm-12" class="pa-0" style="position: relative">
                <div id="map"></div>
                <v-overlay absolute z-index="1" :value="loadingData" color="white">
                    <v-progress-circular :size="100" :width="7" color="deep-orange" indeterminate></v-progress-circular>
                </v-overlay>
            </v-col>
            <v-col md cols="col-sm-12 col-md-4 col-lg-3" class="pa-0" style="position: relative">
                <v-list class="fiscalizacoes py-0 overflow-y-auto" style="max-height: 600px" two-line v-if="fiscalizacoes.length > 0" >
                    <template v-for="(fiscalizacao, index) in fiscalizacoes">
                        <v-list-item :key="fiscalizacao.id" :class="getFinalizadoClass(fiscalizacao)"  :to="{ name: 'fiscalizacao_view', params: { id: parseInt(fiscalizacao.id) }}" >
                            <v-list-item-avatar>
                                <v-icon class="light-green lighten-3" v-text="getItemIcon(fiscalizacao.tipo)"></v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-html="fiscalizacao.financiamento.mutuario"></v-list-item-title>
                                <v-list-item-subtitle>
                                    Valor Financiado: {{fiscalizacao.financiamento.valor | formatNumber}}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action class="mt-0" v-if="fiscalizacao.dataFiscalizacao">
                                <v-list-item-action-text>
                                    {{new Date(fiscalizacao.dataFiscalizacao) | moment("DD MMM YYYY")}}
                                </v-list-item-action-text>
                            </v-list-item-action>
                            <div v-if="fiscalizacao.dataCancelamento" class="d-flex justify-center align-center"
                                 style="position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: repeating-linear-gradient(45deg,transparent,transparent 30px,rgba(255,0,0,0.1) 1px, rgba(255,0,0,0.1) 60px
  )">
                                <span class="text-h6 font-weight-black">CANCELADO</span>
                            </div>
                        </v-list-item>

                        <v-divider inset></v-divider>
                    </template>
                </v-list>
                <div v-else class="text-center ma-6 d-flex align-center" style="height: 100%">
                    <span class="body-1">Clique em alguma cidade no mapa para visualizar as fiscalizações</span>
                </div>

                <v-overlay absolute z-index="1" :value="loadingFiscalizacoes" color="white" opacity="0.8" />
                <v-progress-linear class="mt-3" :active="loadingFiscalizacoes" indeterminate
                                   absolute top color="secondary" />
            </v-col>
        </v-row>
        <v-btn-toggle v-model="visualizationMode" style="display: none"
                      class="custom_toggle" tile dense
                      color="primary" group borderless mandatory>
            <v-btn small :value="1">Pontos</v-btn>
            <v-btn small :value="2">Agrupado</v-btn>
            <v-btn small :value="3">Calor</v-btn>
        </v-btn-toggle>
    </v-card>
</template>


<script>

    import GoogleMapsInit from "../../../assets/GoogleMapsInit";
    import MarkerClusterer from 'marker-clusterer-plus';

    import gql from 'graphql-tag';
    import {getFinalizadoClass, getIconByFiscalizacaoTipo} from "../../../assets/FiscaizacaoUtil";

    export default {
        name: "FiscalizacoesMap",
        data(){
            return{
                cidadesComLocalizacoes: [],
                googleMapsInstance: null,
                markers: [],
                markerCluster: null,
                heatMapLayer: null,
                brasilLocation: {lat: -15.35, lng: -56.06},
                clusterEnabled: false,
                fiscalizacoes: [],
                loadingData: false,
                loadingFiscalizacoes: false,
                visualizationMode: 1,
            }
        },
        watch: {
            visualizationMode(value){
                if(!this.markerCluster && !this.googleMapsInstance){
                    return;
                }

                switch (value) {
                    case 1:
                        this.markers.forEach((marker) => {
                            marker.setMap(this.googleMapsInstance);
                        })
                        this.markerCluster.setMap(null);
                        this.heatMapLayer.setMap(null);
                        break;
                    case 2:
                        this.markers.forEach((marker) => {
                            marker.setMap(this.googleMapsInstance);
                        })
                        this.markerCluster.setMap(this.googleMapsInstance);
                        this.heatMapLayer.setMap(null);
                        break;
                    case 3:
                        this.markerCluster.setMap(null);
                        this.markers.forEach((marker) => {
                            marker.setMap(null);
                        })
                        this.heatMapLayer.setMap(this.googleMapsInstance);
                        break;
                }
            }
        },
        methods: {
            initMap() {
                return new Promise((resolve, reject) => {
                    if (this.googleMapsInstance) {
                        resolve();
                        return;
                    }

                    GoogleMapsInit().then((google) => {

                        this.googleMapsInstance = new google.maps.Map(document.getElementById('map'), {
                            zoom: 4,
                            center: this.brasilLocation,
                            mapTypeId: 'terrain',
                            disableDefaultUI: true,
                            zoomControl: true,
                            mapTypeControl: true,
                            fullscreenControl: true
                        });

                        let centerControlDiv = document.createElement('div');
                        this.createToggleControl(centerControlDiv, this);

                        centerControlDiv.index = 1;
                        this.googleMapsInstance.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);

                        resolve(google);
                    }).catch(error => {
                        reject(error);
                    });
                });


            },
            createInfoWindowContent(cidade, context){
                let infowincontent = document.createElement('div');
                let span = document.createElement('span');
                span.setAttribute('class','font-weight-medium d-block');
                span.textContent = cidade.nome + ' - ' + cidade.estado.sigla;
                infowincontent.appendChild(span);

                let span2 = document.createElement('span');
                span2.setAttribute('class','font-weight-regular d-block');
                let totalFiscalizacoes = cidade.totalFiscalizacoes;
                let label = totalFiscalizacoes > 1 ? ' fiscalizações' : ' fiscalização'
                span2.textContent = totalFiscalizacoes + label;
                infowincontent.appendChild(span2);

                return infowincontent;
            },
            createMarkers(google, cidades){
                return new Promise((resolve, reject) => {
                    let infoWindow = new google.maps.InfoWindow;
                    let context = this;
                    google.maps.event.addListener(infoWindow,'closeclick',function(){
                        context.clearFiscalizacoes();
                    }, context);

                    //icons https://github.com/Concept211/Google-Maps-Markers

                    let markers = cidades.map((cidade) => {

                        let marker = new google.maps.Marker({
                            map: context.googleMapsInstance,
                            icon: {
                                url: '/img/map_dot.png',
                                //scaledSize: new google.maps.Size(16, 16), // scaled size
                            },
                            position: JSON.parse(cidade.coordenadas)
                        });

                        marker.addListener('click', function() {
                            infoWindow.setContent(context.createInfoWindowContent(cidade, context));
                            infoWindow.open(context.googleMapsInstance, marker);
                            context.getFiscalizacoesPorCidade(cidade.id)
                        }, context);

                        return marker;

                    }, context);

                    resolve(markers);
                });
            },
            createMarkersCluster(markers){
                return new Promise((resolve, reject) => {
                    this.markerCluster = new MarkerClusterer(this.clusterEnabled ? this.googleMapsInstance : null, markers,
                        {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

                    resolve();
                });
            },
            createHeatMapLayer(google, cidades){
                return new Promise((resolve, reject) => {
                    let context = this;
                    let heatmapData = [];
                    cidades.forEach((cidade) => {
                        let coords = JSON.parse(cidade.coordenadas);
                        //var latLng = new google.maps.LatLng(coords[1], coords[0]);
                        let latLng = new google.maps.LatLng(coords);
                        //heatmapData.push(latLng);
                        heatmapData.push({location: latLng, weight: 0.5 * cidade.totalFiscalizacoes});
                    })
                    this.heatMapLayer = new google.maps.visualization.HeatmapLayer({
                        data: heatmapData,
                        dissipating: true,
                        //radius: 5,
                        maxIntensity: 2,
                        map: this.visualizationMode === 3 ? context.googleMapsInstance : null
                    });
                    resolve();
                });
            },
            createToggleControl(controlDiv, context) {
                let controlUI = document.createElement('div');
                controlUI.style.backgroundColor = '#fff';
                controlUI.style.border = '2px solid #fff';
                controlUI.style.borderRadius = '3px';
                controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
                controlUI.style.marginBottom = '22px';
                controlUI.style.textAlign = 'center';
                let buttonGroup = context.$el.querySelector(".custom_toggle")
                buttonGroup.style.display = 'block';
                controlUI.appendChild(buttonGroup);

                controlDiv.appendChild(controlUI);

            },
            getItemIcon(tipo){
                return getIconByFiscalizacaoTipo(tipo);
            },
            getFinalizadoClass(fiscalizacao){
                return getFinalizadoClass(fiscalizacao);
            },
            clearFiscalizacoes(){
                this.fiscalizacoes = [];
            },
            getLocaisFiscalizacoes(){
                return new Promise((resolve, reject) => {
                    this.$apollo.query({
                        query: gql`query {
                            cidades:cidadesComFiscalizacao{
                                id
                                nome
                                estado{
                                    sigla
                                }
                                coordenadas
                                totalFiscalizacoes:totalFiscalizacoesPorCidade
                            }
                        }`,
                    }).then(result => {
                        resolve(result.data.cidades);
                    }).catch(() => {
                    });
                });
            },
            getFiscalizacoesPorCidade(cidadeId){
                this.loadingFiscalizacoes = true;
                return new Promise((resolve, reject) => {
                    this.$apollo.query({
                        query: gql`query($cidadeId: Int!) {
                            fiscalizacoes:fiscalizacoesPorCidade(cidadeId: $cidadeId){
                                id
                                numeroSolicitacao
                                dataFiscalizacao
                                dataLimite
                                regular
                                tipo{
                                    nome
                                }
                                financiamento{
                                    valor
                                    mutuario
                                }
                                dataCancelamento
                            }
                        }`,
                        variables: {cidadeId}
                    }).then(result => {
                        this.fiscalizacoes = result.data.fiscalizacoes.sort((a, b) => {
                            return new Date(a.dataLimite) - new Date(b.dataLimite);
                        });
                        this.loadingFiscalizacoes = false;
                        resolve(this.fiscalizacoes);
                    }).catch(() => {
                        this.loadingFiscalizacoes = false;
                    });
                });
            }

        },
        mounted() {
            this.initMap().then((google) => {
                if(google === null){
                    return;
                }
                this.loadingData = true;
                this.getLocaisFiscalizacoes().then(cidades => {
                    this.cidadesComLocalizacoes = cidades.filter(cidade => cidade.coordenadas !== null);

                    this.createMarkers(google, this.cidadesComLocalizacoes)
                        .then((markers) => {
                            this.markers = markers;
                            return this.createMarkersCluster(markers);
                        })
                        .then(()=>{
                            return this.createHeatMapLayer(google, this.cidadesComLocalizacoes)
                        })
                        .finally(()=>{
                            setTimeout(() => {
                                this.loadingData = false;
                            }, 1000)
                        })
                });
            })
        }
    }
</script>

<style scoped>
    #map {
        height: 600px;  /* The height is 400 pixels */
        width: 100%;  /* The width is the width of the web page */
    }
    @media(max-width: 599px){
        #map {
            height: 300px;  /* The height is 400 pixels */
        }
    }
    .v-list-item-finalizado{
        background-color: #f9f9f9;
    }
    .v-list-item-finalizado .v-list-item__title,
    .v-list-item-finalizado .v-list-item__subtitle,
    .v-list-item-finalizado .v-list-item__action-text{
        opacity: 0.7;
    }
    .v-list-item-finalizado::after{
        width: 6px;
        content: "";
        display: inline-block;
        height: 72px;
        margin-right: -16px;
        margin-left: 16px;
    }
    .finalizado-regular::after{
        background-color: #4CAF50;
    }
    .finalizado-irregular::after{
        background-color: #f31b1b;
    }
</style>